import { apiClient } from 'src/api-client';
import Config from '../../config/Global';
import { CREATE_ADMIN_SUCCESS, UPDATE_ADMIN_SUCCESS } from 'src/utils/constant';

export const createAdminSerivce = async (data: any) => {
  try {
    const url = `${Config.baseUrl}/admin`;

    const dataRes = await apiClient<{ status: boolean; data: any }>(
      url,
      'POST',
      data
    );
    if (dataRes.status) {
      return {
        status: true,
        data: CREATE_ADMIN_SUCCESS
      };
    }
    return {
      status: false,
      data: dataRes.data
    };
  } catch (error) {
    return {
      status: false,
      dataRes: error
    };
  }
};

export const updateAdminSerivce = async (id: number, data: any) => {
  try {
    const url = `${Config.baseUrl}/admin/${id}`;

    const dataRes = await apiClient<{ status: boolean; data: any }>(
      url,
      'PUT',
      data
    );

    if (dataRes?.status) {
      return {
        status: true,
        data: UPDATE_ADMIN_SUCCESS
      };
    }
    return {
      status: false,
      data: dataRes.data
    };
  } catch (error) {
    return {
      status: false,
      dataRes: error
    };
  }
};

export const getAdminService = async (id: number) => {
  try {
    const url = `${Config.baseUrl}/admin/get/${id}`;

    const dataRes = await apiClient<{ status: boolean; data: any }>(url);
    return dataRes;
  } catch (error) {
    return null;
  }
};

export const getAllAdminService = async () => {
  try {
    const url = `${Config.baseUrl}/admin/all`;
    const dataRes = await apiClient<{ status: boolean; data: any }>(url);
    return dataRes;
  } catch (error) {
    return null;
  }
};

export const deleteAdminService = async (id: number) => {
  try {
    const url = `${Config.baseUrl}/admin/delete/${id}`;
    const dataRes = await apiClient<{ status: boolean; message: string }>(
      url,
      'DELETE'
    );
    return dataRes;
  } catch (error) {
    return error;
  }
};
