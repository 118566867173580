import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import PageHeader from 'src/components/PageHeader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputMask from 'react-input-mask';
import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserRegisterForm } from 'src/models/waiter';
import { LoadingContext } from 'src/contexts/LoadingContext';
import { NotifyContext } from 'src/contexts/NotifyContext';
import { LOADING } from 'src/utils/constant';
import {
  createAdminSerivce,
  getAdminService,
  updateAdminSerivce
} from 'src/services/User';

function CreateUser() {
  let navigate = useNavigate();
  const { id } = useParams();

  const { setNotifyOption } = useContext(NotifyContext);

  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [userData, setUserData] = useState<UserRegisterForm>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  });

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        startLoading('Loading information of a waiter...');
        const response = await getAdminService(Number(id));
        console.log('----response---- ', response);
        if (response.status) {
          setUserData({ ...response.data, password: '' });
        } else {
          setNotifyOption({
            title: 'Error',
            message: response.data,
            type: 'danger'
          });
        }
        stopLoading();
      }
    };
    loadData();
  }, [id]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { phoneNumber } = userData;

    if (phoneNumber.indexOf('_') !== -1) {
      setNotifyOption({
        title: 'Error',
        message: 'Por favor ingrese el número de teléfono correcto.',
        type: 'danger'
      });
      return;
    }

    startLoading(LOADING);

    const redirectUrl = '/admin/users/list';

    let response: any;
    if (id) {
      response = await updateAdminSerivce(Number(id), userData);
    } else {
      response = await createAdminSerivce(userData);
    }
    stopLoading();
    if (response.status) {
      setNotifyOption({
        title: 'Success',
        message: response.data,
        type: 'success'
      });
      return navigate(redirectUrl);
    } else {
      setNotifyOption({
        title: 'Error',
        message: response.data,
        type: 'danger'
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <PageHeader title="Alta de usuario" subtitle="Alta de usuario" />
      <Box padding={3}>
        <Box
          padding={3}
          sx={{
            background: 'white',
            boxShadow: '0px 2px 5px 5px lightgray',
            borderRadius: '5px'
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container direction="row">
              <Grid xs={12} item>
                <Typography variant="h4">
                  Formulario de alta de personal
                </Typography>
              </Grid>
              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Nombre:</FormHelperText>
                <OutlinedInput
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleChange}
                  fullWidth
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight'
                  }}
                  required
                />
              </Grid>
              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Apellidos:</FormHelperText>
                <OutlinedInput
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Teléfono</FormHelperText>
                <InputMask
                  mask="999-99-99-99"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                >
                  <OutlinedInput name="phoneNumber" fullWidth required />
                </InputMask>
              </Grid>

              <Grid md={6} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Email</FormHelperText>
                <OutlinedInput
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  readOnly={!!id}
                />
              </Grid>

              <Grid md={6} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Contraseña</FormHelperText>
                <OutlinedInput
                  type="password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  fullWidth
                  required={!id}
                />
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Button variant="contained" type="submit">
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
}

export default CreateUser;
