import { Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function PageHeader(props) {
  const theme = useTheme();
  const { title, subtitle } = props;

  return (
    <Box
      sx={{
        background: theme.colors.primary.lighter,
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px'
      }}
    >
      <Typography variant="body1" color="primary">
        {title}
      </Typography>
      <Stack direction="row">
        <Typography variant="subtitle1" color="primary">
          Inicio
        </Typography>
        {subtitle && (
          <Typography variant="subtitle1">- &nbsp;{subtitle}</Typography>
        )}
      </Stack>
    </Box>
  );
}

export default PageHeader;
