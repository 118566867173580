import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  adminRouter,
  guestRouter,
  superadminRouter,
  waiterRouter
} from './loader';
import CreateUser from './content/applications/Users/CreateUser';
import ApplicationCreate from './content/applications/Application/ApplicationCreate';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader message="" />}>
      <Component {...props} />
    </Suspense>
  );

const UserLogin = Loader(
  lazy(() => import('./content/pages/Auth/Login/UserLogin'))
);
const AuthLogin = Loader(
  lazy(() => import('./content/pages/Auth/Login/AuthLogin'))
);
const Register = Loader(lazy(() => import('./content/pages/Auth/Register')));
const Forgot = Loader(lazy(() => import('./content/pages/Auth/Forgot')));

const WaitersList = Loader(
  lazy(() => import('src/content/applications/Waiters/List'))
);
const WaitersCreate = Loader(
  lazy(() => import('src/content/applications/Waiters/Create'))
);
const WaitersRegisterEvent = Loader(
  lazy(() => import('src/content/applications/Waiters/Create/indexForWaiter'))
);
const WaitersView = Loader(
  lazy(() => import('src/content/applications/Waiters/View'))
);
const WaitersHistory = Loader(
  lazy(() => import('src/content/applications/Waiters/History'))
);

const TimeHistory = Loader(
  lazy(() => import('src/content/applications/Timecontrol/TimeHIstory'))
);

const TimeManage = Loader(
  lazy(() => import('src/content/applications/Timecontrol/TimeManage'))
);

const TimeReport = Loader(
  lazy(() => import('src/content/applications/Timecontrol/TimeReport'))
);

const Users = Loader(lazy(() => import('src/content/applications/Users')));
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const Dashboard = Loader(lazy(() => import('src/content/dashboards')));

const WaiterProfile = Loader(
  lazy(() => import('./content/applications/User/WaiterProfile'))
);

const Messenger = Loader(
  lazy(() => import('./content/applications/Messenger'))
);
const PartyList = Loader(
  lazy(() => import('./content/applications/Parties/List'))
);
const PartyCreate = Loader(
  lazy(() => import('./content/applications/Parties/Create'))
);
const PartyConfigure = Loader(
  lazy(() => import('./content/applications/Parties/Configure'))
);
const PartyEdit = Loader(
  lazy(() => import('./content/applications/Parties/Edit'))
);

const routes: any = createBrowserRouter([
  {
    path: '',
    element: <Navigate to="dashboard" replace />
  },
  {
    path: '',
    element: <SidebarLayout />,
    loader: waiterRouter,
    children: [
      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'my-history',
        element: <TimeHistory />
      },
      {
        path: 'time-manage',
        element: <TimeManage />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'user',
        children: [
          {
            path: 'profile',
            element: <WaiterProfile />
          }
        ]
      }
    ]
  },
  {
    path: '/user',
    element: <BaseLayout />,
    loader: guestRouter,
    children: [
      {
        path: 'login',
        element: <UserLogin />
      }
    ]
  },
  {
    path: '/admin/users',
    element: <SidebarLayout />,
    loader: superadminRouter,
    children: [
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'create',
        element: <CreateUser />
      },
      {
        path: 'edit/:id',
        element: <CreateUser />
      }
    ]
  },
  {
    path: '/auth',
    element: <BaseLayout />,
    loader: guestRouter,
    children: [
      {
        path: 'login',
        element: <AuthLogin />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'forgot',
        element: <Forgot />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    loader: adminRouter,
    children: [
      {
        path: 'waiters/list',
        element: <WaitersList />
      },
      {
        path: 'waiters/create',
        element: <WaitersCreate />
      },
      {
        path: 'waiters/register',
        element: <WaitersRegisterEvent />
      },
      {
        path: 'waiter/edit/:id',
        element: <WaitersCreate />
      },
      {
        path: 'waiters/history/:id',
        element: <WaitersHistory />
      },
      {
        path: 'waiters/view/:id',
        element: <WaitersView />
      },
      {
        path: 'party/list',
        element: <PartyList />
      },
      {
        path: 'party/create',
        element: <PartyCreate />
      },
      {
        path: 'party/configure',
        element: <PartyConfigure />
      },
      {
        path: 'party/edit/:partyId',
        element: <PartyEdit />
      },
      {
        path: 'time-report',
        element: <TimeReport />
      }
    ]
  },
  {
    path: 'status',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="404" replace />
      },
      {
        path: '404',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: 'application',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <ApplicationCreate />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
]);

export default routes;
