import Config from '../../config/Global';
export const uploadResourceService = async (data: FormData) => {
  try {
    const url = `${Config.baseUrl}/resource/general-upload`;
   const response = await fetch(url, {
      method: 'POST',
      body: data
    });
    if (response.ok) {
      const dataRes = await response.json();
      return dataRes.resourceId;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const generalUploadResourceService = async (data: FormData) => {
  try {
    const url = `${Config.baseUrl}/resource/general-upload`;
   const response = await fetch(url, {
      method: 'POST',
      body: data
    });
    if (response.ok) {
      const dataRes = await response.json();
      return dataRes.resourceId;
    }
    return null;
  } catch (error) {
    return null;
  }
};
