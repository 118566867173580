import { FC, useState, createContext } from 'react';
// import { useNavigate } from 'react-router-dom';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  url: string;
  goto: (_url: string) => void;
  loadUrl: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [url, setUrl] = useState("");
  // const navigate = useNavigate();

  const goto = (_url: string) => {
    setUrl(_url);
    // navigate(_url);
  }

  const loadUrl = () => {
    const _url = new URL(window.location.href);
    setUrl(_url.pathname);
  }

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar, url, goto, loadUrl }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
