import {
  // useNavigate,
  redirect
} from 'react-router-dom';
import { userProfile } from './services/Auth/Auth';

export const superadminRouter = async () => {
  const user = await userProfile();
  if (!user) {
    return redirect('/auth/login');
  }
  if (user.role !== 'superadmin') {
    return redirect('/dashboard');
  }
  return null;
};
export const adminRouter = async () => {
  const user = await userProfile();
  if (!user) {
    return redirect('/auth/login');
  }
  if (user.role !== 'admin') {
    return redirect('/dashboard');
  }
  return null;
};

export const waiterRouter = async () => {
  const user = await userProfile();
  console.log("my-shiotry", user)
  if (!user) {
    return redirect('/auth/login');
  }
  return null;
};

export const guestRouter = async () => {
  const user = await userProfile();
  if (user) {
    return redirect('/dashboard');
  }
  return null;
};
