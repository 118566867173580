import { useEffect } from 'react';
import {
  //  useRoutes,
  RouterProvider
 } from 'react-router-dom';
import router from 'src/router';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useContext } from 'react';
import { LoadingContext } from './contexts/LoadingContext';
import SuspenseLoader from './components/SuspenseLoader';
import { UserContext } from './contexts/UserContext';
import { SidebarContext } from './contexts/SidebarContext';
import { NotifyContext } from './contexts/NotifyContext';
import Notify from './components/Notify';

function App() {
  // const content = useRoutes(router);
  const { isLoading, loadingMessage } = useContext(LoadingContext);
  const { notifyOption } = useContext(NotifyContext);
  const { autoLogin } = useContext(UserContext);
  const { goto, loadUrl } = useContext(SidebarContext);

  useEffect(() => {
    const isLogged = autoLogin();
    if (!isLogged) {
      goto('/auth/login');
    } else {
      loadUrl();
    }
  }, []);

  return (
    <ThemeProvider>
      <Notify options={notifyOption} />
      <CssBaseline />
      {/* {content} */}
      <RouterProvider router={router} />
      {isLoading && <SuspenseLoader message={loadingMessage} />}
    </ThemeProvider>
  );
}
export default App;
