import {
  useContext,
  // useEffect,
  useState
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ListSubheader, alpha, Box, List, styled, Button } from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  AddCircle,
  CalendarMonth,
  // Chat,
  CreditScore,
  FormatListBulleted,
  History,
  PermContactCalendar,
  PersonAddAlt,
  // Woman,
  // Edit
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { UserContext } from 'src/contexts/UserContext';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: 0;
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(20)};
      color: ${theme.colors.alpha.black[100]};
      line-height: 1.4;
      padding: 30px;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          font-weight: 600;
          display: flex;
          color: ${theme.colors.secondary.light};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.primary.light};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.primary.dark};
            font-weight: bold;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.primary.dark};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SideBarInfo = [
  {
    url: '/dashboard',
    icon: <WidgetsIcon color="secondary" />,
    text: 'Escritorio',
    key: '/dashboard'
  },
  {
    url: '/admin/users/list',
    icon: <PeopleIcon color="secondary" />,
    text: 'Usuarios',
    key: '/admin/users/list',
    role: ['superadmin']
  },
  {
    url: '/admin/users/create',
    icon: <PersonAddIcon color="secondary" />,
    text: 'Crear',
    key: '/admin/users/create',
    role: ['superadmin']
  },
  {
    url: '/management/waiters/list',
    icon: <PermContactCalendar color="secondary" />,
    text: 'Personal',
    isParent: true,
    role: ['admin'],
    key: '/management/waiters/list',
    children: [
      {
        url: '/management/waiters/create',
        icon: <PersonAddAlt color="secondary" />,
        text: 'Alta manual',
        isParent: false,
        key: '/management/waiters/create'
      },
      // {
      //   url: '/management/waiters/view',
      //   icon: <Woman color="secondary" />,
      //   text: 'Perfil de empleado',
      //   isParent: false,
      //   key: '/management/waiters/view'
      // },
      {
        url: '/management/waiters/history',
        icon: <History color="secondary" />,
        text: 'Historial',
        isParent: false,
        key: '/management/waiters/history'
      }
    ]
  },
  {
    url: 'events',
    icon: <CalendarMonth color="secondary" />,
    text: 'Eventos',
    isParent: true,
    role: ['admin'],
    children: [
      {
        url: '/management/party/list',
        icon: <FormatListBulleted color="secondary" />,
        text: 'Listado de eventos',
        isParent: false,
        key: '/management/party/list'
      },
      {
        url: '/management/party/create',
        icon: <AddCircle color="secondary" />,
        text: 'Nuevo evento',
        isParent: false,
        key: '/management/party/create'
      },
      {
        url: '/management/party/configure',
        icon: <CreditScore color="secondary" />,
        text: 'Tipos de evento',
        isParent: false,
        key: '/management/party/configure'
      },
      // {
      //   url: '/management/party/edit',
      //   icon: <Edit color="secondary" />,
      //   text: 'Editar Evento',
      //   isParent: false,
      //   key: '/management/party/edit'
      // }
    ]
  },
  {
    url: '/management/time-report',
    icon: <DescriptionIcon color="secondary" />,
    text: 'Informe de horas',
    isParent: false,
    role: ['admin'],
    key: '/management/time-report'
  },
  {
    url: '/time-manage',
    icon: <RestaurantIcon color="secondary" />,
    text: 'Control horario',
    isParent: false,
    role: ['waiter'],
    key: '/time-manage'
  },
  {
    url: '/my-history',
    icon: <History color="secondary" />,
    text: 'Historial',
    isParent: false,
    role: ['waiter'],
    key: '/my-history'
  },
  // {
  //   url: '/messenger',
  //   icon: <Chat color="secondary" />,
  //   text: 'Mensajería',
  //   isParent: false,
  //   key: '/messenger',
  //   role: ['admin', 'waiter']
  // },
  // {
  //   url: '/management/users',
  //   icon: <PeopleAlt color="secondary" />,
  //   text: 'Usuarios',
  //   isParent: false,
  //   role: ['admin'],
  //   key: '/management/users'
  // }
];

const InertUrls = [
  '/management/waiters/view',
  '/management/waiters/history',
  '/management/party/edit'
];

function SidebarMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    closeSidebar
    // goto,
    // url
  } = useContext(SidebarContext);
  const [parent, setParent] = useState('');
  const theme = useTheme();
  const { user } = useContext(UserContext);

  // useEffect(() => {
  //     if (user && user.role === 'waiter') {
  //     SideBarInfo[3].url = '/management/waiters/history/' + user.userId;
  //   }
  // }, [user]);

  const handleClickNavLink = (
    _parent: string,
    _url: string,
    isParent: boolean
  ) => {
    if (InertUrls.indexOf(_url) >= 0) {
      return;
    }

    if (isParent) {
      if (parent === _url) {
        setParent('');
      } else setParent(_url);
    } else {
      setParent(_parent);
    }
    if (_url.startsWith('/')) {
      navigate(_url);
      closeSidebar();
    }
  };

  return (
    <>
      <MenuWrapper key={'hello world sidebar mneu'}>
        <List
          key="sidebar-menu"
          component="div"
          subheader={
            <ListSubheader key="sidebar-menu-div" component="div" disableSticky>
              MENÚ PRINCIPAL
            </ListSubheader>
          }
        >
          <SubMenuWrapper key="list item">
            {SideBarInfo?.filter(
              (item: any) => !item.role || item.role.includes(user?.role)
            ).map((item: any, index: number) => {
              return (
                <Box key={'item' + index}>
                  <Box>
                    <Button
                      sx={{
                        background:
                          location.pathname.indexOf(item.key) > -1
                            ? // url.indexOf(item.url) >= 0
                            theme.colors.primary.lighter
                            : 'white',
                        borderLeft:
                          location.pathname.indexOf(item.key) > -1
                            ? // url.indexOf(item.url) >= 0
                            `4px solid ${theme.colors.primary.main}`
                            : '4px solid white',
                        justifyContent: 'left',
                        paddingLeft: 3,
                        borderRadius: 0,
                        boxSizing: 'border-box',
                        '&:hover': {
                          background: theme.colors.primary.lighter,
                          borderLeft: `4px solid ${theme.colors.primary.main}`,
                          fontWeight: 600
                        },
                        fontWeight: 500
                      }}
                      fullWidth
                      disableRipple
                      onClick={() =>
                        handleClickNavLink('', item.url, item.isParent)
                      }
                      startIcon={item.icon}
                    >
                      {item.text}
                    </Button>
                  </Box>
                  {((parent && item.url.indexOf(parent) >= 0) ||
                    (item.children &&
                      item.children.filter(
                        (item_child: any) =>
                          location.pathname.indexOf(item_child.key) >= 0
                      ).length > 0)) && (
                      <SubMenuWrapper key={'submenuwrapper' + index}>
                        {item.children?.map((item_child: any, index: number) => {
                          return (
                            <Box key={'item_child' + item_child.url + index}>
                              <Box>
                                <Button
                                  sx={{
                                    background:
                                      location.pathname.indexOf(item_child.key) >
                                        -1
                                        ? // url.indexOf(item_child.url) >= 0
                                        theme.colors.primary.lighter
                                        : 'white',
                                    borderLeft:
                                      location.pathname.indexOf(item_child.key) >
                                        -1
                                        ? // url.indexOf(item_child.url) >= 0
                                        `4px solid ${theme.colors.primary.main}`
                                        : '4px solid white',
                                    justifyContent: 'left',
                                    paddingLeft: 5,
                                    borderRadius: 0,
                                    boxSizing: 'border-box',
                                    '&:hover': {
                                      background: theme.colors.primary.lighter,
                                      borderLeft: `4px solid ${theme.colors.primary.main}`,
                                      fontWeight: 600
                                    },
                                    fontWeight: 500
                                  }}
                                  fullWidth
                                  disableRipple
                                  onClick={() =>
                                    handleClickNavLink(
                                      item.url,
                                      item_child.url,
                                      item_child.isParent
                                    )
                                  }
                                  startIcon={item_child.icon}
                                >
                                  {item_child.text}
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                      </SubMenuWrapper>
                    )}
                </Box>
              );
            })}
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
