import { useEffect } from 'react';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export default function Notify({ options }) {
  useEffect(() => {
    if (options.title) {
      Store.addNotification({
        ...options,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }, [options]);
  return (
    <>
      <ReactNotifications />
    </>
  );
}
