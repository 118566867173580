import config from './config/Global';

export const apiClient = async <T>(
  endpoint: string,
  method: string = 'GET',
  body?: any
): Promise<T> => {
  const accessToken = localStorage.getItem(config.authStorageKey);
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };

  // Define options for the fetch request
  const options: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  };

  try {
    // Make the API request
    const response = await fetch(endpoint, options);

    // console.log('response : ', response);

    // Throw an error for non-successful responses
    if (!response.ok) {

      if (response.status === 401) {
        localStorage.removeItem(config.authStorageKey);
        localStorage.removeItem('user');
        // console.log('33');
      }

      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Do not attempt to parse JSON if the status code is 204
    // if (response.status === 204) {
    //   return null;
    // }

    // Parse the JSON response and return it
    return await response.json();
  } catch (error) {
    // Handle any errors that occur during the request
    console.error('API request failed:', error);
    throw error;
  }
};
