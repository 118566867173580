import { FC, useState, createContext } from 'react';
type WaiterContext = {
    profile: any;
    setViewProfile: (_profile: any) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WaiterContext = createContext<WaiterContext>(
  {} as WaiterContext
);

export const WaiterProvider: FC = ({ children }) => {
    const [profile, setProfile] = useState<any>(null);

    const setViewProfile = (_profile: any) => {
        setProfile(_profile);
        console.log(_profile);
    }


  return (
    <WaiterContext.Provider
      value={{ profile, setViewProfile }}
    >
      {children}
    </WaiterContext.Provider>
  );
};
