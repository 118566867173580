import { useRef, useState, useContext } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { AccountBox } from '@mui/icons-material';
import { UserContext } from 'src/contexts/UserContext';
import { Avatar } from '@mui/material';
import config from 'src/config/Global';
// import { SidebarContext } from 'src/contexts/SidebarContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
        display: flex;
        align-items: center;
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.primary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { user } = useContext(UserContext);
  // const { goto } = useContext(SidebarContext);
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  // useEffect(()=>{
  //   if ( !user ) {
  //     navigate("/auth/login")
  //   }
  // }, [user])

  const _user = {
    name: '@' + user?.firstName + ' ' + user?.lastName,
    // avatar: user?.avatarUrl || '/static/images/avatars/default.png',
    avatar: user?.avatarUrl
      ? config.baseUrl + '/resource/get/' + user.avatarUrl
      : '/static/images/avatars/default.png',
    jobtitle: user?.role
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = () => {
    logout();
    navigate('/auth/login');
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar alt={_user.name} src={_user.avatar} />
        {/* <Person /> */}
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{_user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {_user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }}>
          <Avatar alt={_user.name} src={_user.avatar} />
          {/* <Person color='primary'/> */}
          <UserBoxText>
            <UserBoxLabel variant="body1">{_user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {_user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/user/profile" component={NavLink}>
            {/* <AccountBoxTwoToneIcon fontSize="small" /> */}
            <AccountBox />
            <ListItemText primary="Profile" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button onClick={handleSignOut} color="primary" fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
