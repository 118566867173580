import { FC, useState, createContext } from 'react';

type NotifyType = {
  title: string;
  message: string;
  type: string;
};

type NotifyContext = {
  notifyOption: NotifyType;
  setNotifyOption: (notify: NotifyType) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotifyContext = createContext<NotifyContext>({} as NotifyContext);

export const NotifyProvider: FC = ({ children }) => {
  const [notifyOption, setNotifyOption] = useState({
    title: '',
    message: '',
    type: 'success'
  });

  return (
    <NotifyContext.Provider value={{ notifyOption, setNotifyOption }}>
      {children}
    </NotifyContext.Provider>
  );
};
