import { FC, useState, createContext, useContext, useEffect } from 'react';
import {
  loginService,
  resetService,
  updateProfile,
  userProfile
  // refreshTokenService
} from 'src/services/Auth/Auth';
import { LoadingContext } from './LoadingContext';
import config from '../config/Global';
import { LOADING } from 'src/utils/constant';

type UserContext = {
  user: any;
  autoLogin: () => boolean;
  login: (any) => any;
  logout: () => void;
  resetPassword: (email: string) => any;
  updateProfileSerivce: (data: any) => any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<UserContext>({} as UserContext);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const login = async (userInfo: any) => {
    try {
      const email = userInfo.email;
      const password = userInfo.password;
      const rememberMe = userInfo.rememberMe;

      startLoading('Login...');
      const result = await loginService(email, password, rememberMe);
      stopLoading();

      if (result.status) {
        const response = result.data;
        localStorage.setItem(config.authStorageKey, response.accessToken);
        localStorage.setItem('user', response);
        setUser(response);
      }
      return result;
    } catch (error) {
      return null;
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem(config.authStorageKey);
      localStorage.removeItem('user');
      setUser(null);
    } catch (error) {}
  };

  const autoLogin = () => {
    try {
      const savedUser = JSON.parse(localStorage.getItem(config.authStorageKey));
      if (savedUser && savedUser.email) {
        if (
          new Date(savedUser.accessTokenExpiry).getTime() > new Date().getTime()
        ) {
          setUser(savedUser);
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      startLoading(LOADING);
      const result = await resetService(email);
      stopLoading();
      return result;
    } catch (error) {
      return null;
    }
  };

  async function loaduser() {
    const user = await userProfile();
    setUser(user);
  }

  const updateProfileSerivce = async (data: any) => {
    try {
      startLoading(LOADING);
      const result = await updateProfile(data);
      stopLoading();
      loaduser();
      return result;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    loaduser();
  }, [userProfile]);

  return (
    <UserContext.Provider
      value={{
        user,
        autoLogin,
        login,
        logout,
        resetPassword,
        updateProfileSerivce
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
