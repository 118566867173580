import { apiClient } from 'src/api-client';
import config from '../../config/Global';

export const loginService = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  try {
    const response = await apiClient<any>(
      `${config.baseUrl}/auth/sign_in_with_email_password`,
      'POST',
      { email, password, rememberMe }
    );

    return response;
  } catch (error) {
    return null;
  }
};

export const resetService = async (email: string) => {
  try {
    const response = await apiClient<{ status: boolean; data: any }>(
      `${config.baseUrl}/auth/reset-password`,
      'POST',
      { email }
    );

    return response;
  } catch (error) {
    return {
      status: false,
      data: error
    };
  }
};

export const registerService = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  role: string
) => {
  try {
    const url = `${config.baseUrl}/auth/signup`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        role
      })
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const refreshTokenService = async (
  accessToken: string,
  refreshToken: string
) => {
  try {
    const url = `${config.baseUrl}/auth/refreshToken`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        accessToken,
        refreshToken
      })
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const userProfile = async () => {
  try {
    const response = await apiClient<any>(`${config.baseUrl}/profile`, 'GET');
    return response;
  } catch (error) {
    return null;
  }
};

export const updateProfile = async (data: any) => {
  try {
    const response = await apiClient<any>(
      `${config.baseUrl}/profile`,
      'PUT',
      data
    );
    return response;
  } catch (error) {
    return null;
  }
};
