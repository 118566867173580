import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
// import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { LoadingProvider } from './contexts/LoadingContext';
import { UserProvider } from './contexts/UserContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { WaiterProvider } from './contexts/WaiterContext';
import { NotifyProvider } from './contexts/NotifyContext';
import './main.css';

ReactDOM.render(
  // <BrowserRouter>
    <HelmetProvider>
      <NotifyProvider>
        <LoadingProvider>
          <UserProvider>
            <SidebarProvider>
              <WaiterProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </WaiterProvider>
            </SidebarProvider>
          </UserProvider>
        </LoadingProvider>
      </NotifyProvider>
    </HelmetProvider>
  // </BrowserRouter>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
