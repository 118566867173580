import { useContext } from 'react';

import {
  Box,
  alpha,
  IconButton,
  Tooltip,
  styled,
  Table,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderUserbox from './Userbox';
// import HeaderButtons from './Buttons';
import HeaderSearch from './Buttons/Search';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: 10px 20px;
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{
              padding: 0,
              border: "none"
            }}>
              <HeaderSearch />
            </TableCell>
            <TableCell sx={{
              padding: 0,
              width: "380px",
              border: "none"
            }}>
              <Box display="flex" alignItems="center" justifyContent="right">
                {/* <HeaderButtons /> */}
                <HeaderUserbox />
                <Box
                  component="span"
                  sx={{
                    display: { lg: 'none', xs: 'inline-block' }
                    // display: 'inline-block'
                  }}
                >
                  <Tooltip arrow title="Toggle Menu">
                    <IconButton color="primary" onClick={toggleSidebar}>
                      {!sidebarToggle ? (
                        <MenuTwoToneIcon fontSize="small" />
                      ) : (
                        <CloseTwoToneIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>

      </Table>
    </HeaderWrapper>
  );
}

export default Header;
