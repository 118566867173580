import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectOption } from 'src/utils/globalTypes';

type WAISlectProp = {
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  options: SelectOption[];
  label: string;
  name: string;
  required?: boolean;
};

export default function WAISelect({
  handleChange,
  name,
  value,
  options,
  label,
  required = false
}: WAISlectProp) {
  return (
    <FormControl fullWidth>
      <InputLabel id="select-province-label">{label}</InputLabel>
      <Select
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
        name={name}
        label={label}
        required={required}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
