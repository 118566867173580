import { Typography } from '@mui/material';
import {
  Box,
  alpha,
  styled,
} from '@mui/material';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.footer.height};
        color: ${theme.footer.textColor};
        right: 0;
        bottom: 0;
        padding: 20px 20px;
        text-align: center;
        z-index: 104;
        background-color: ${alpha(theme.footer.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

const CopyrightText = styled(Typography)(
    () => `
    color: gray;
    display: inline;
    `
)

function Footer() {

  return (
    <FooterWrapper>
        <CopyrightText> 2023 Copyright © </CopyrightText> Catering Domenico
    </FooterWrapper>
  );
}

export default Footer;
