import { Box, Grid, FormControl, FormHelperText } from '@mui/material';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

import PageHeader from 'src/components/PageHeader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputMask from 'react-input-mask';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { ExternalApplicationRegisterForm } from 'src/models/waiter';
import { LoadingContext } from 'src/contexts/LoadingContext';
import WAISelect from 'src/components/WAISelect';
import {
  APPLICATION_SUBMIT,
  SUCCESS_MSG,
  profileFileType,
  provinceList
} from 'src/utils/constant';
import { NotifyContext } from 'src/contexts/NotifyContext';
import dayjs from 'dayjs';
import config from 'src/config/Global';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  generalUploadResourceService,
  uploadResourceService
} from 'src/services/Resource';

const initApplication = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  dni: '',
  birthday: '01/01/2000',
  nationalID: '',
  street: '',
  city: '',
  province: '',
  postalCode: '',
  reCaptchaKey: '',
  certification: '',
  ssn: '',
  iban: '',
  avatarUrl: ''
};

function ApplicationCreate() {
  const { setNotifyOption } = useContext(NotifyContext);

  let captcha;

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [waiterData, setWaiterData] =
    useState<ExternalApplicationRegisterForm>(initApplication);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const { postalCode, phoneNumber, reCaptchaKey, iban, avatarUrl } =
        waiterData;
      if (postalCode.indexOf('_') !== -1) {
        setNotifyOption({
          title: 'Error',
          message: 'Por favor ingrese el código postal correcto.',
          type: 'danger'
        });
        return;
      }
      if (phoneNumber.indexOf('_') !== -1) {
        setNotifyOption({
          title: 'Error',
          message: 'Por favor ingrese el número de teléfono correcto.',
          type: 'danger'
        });
        return;
      }

      if (iban.indexOf('_') !== -1) {
        setNotifyOption({
          title: 'Error',
          message: 'Por favor ingrese el código IBAN correcto.',
          type: 'danger'
        });
        return;
      }

      if (!avatarUrl) {
        setNotifyOption({
          title: 'Error',
          message: 'Por favor seleccione la foto de perfil.',
          type: 'danger'
        });
        return;
      }

      if (!reCaptchaKey) {
        setNotifyOption({
          title: 'Error',
          message: 'Please complete recaptcha.',
          type: 'danger'
        });
        return;
      }

      let startLoadingMsg = 'Creating a new waiter...';
      // let successMsg = 'El usuario ha sido dado de alta correctamente.';
      // let redirectUrl = '/management/waiters/list';

      startLoading(startLoadingMsg);

      const url = `${config.baseUrl}/public/new-waiter`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(waiterData)
      });
      const res = await response.json();
      stopLoading();
      if (res.status) {
        setNotifyOption({
          title: 'Success',
          message: SUCCESS_MSG,
          type: 'success'
        });
        captcha.reset();
        setWaiterData(initApplication);
      } else {
        setNotifyOption({
          title: 'Error',
          message: res.message,
          type: 'danger'
        });
      }
    } catch (error) {
      console.log({ error });
      setNotifyOption({
        title: 'Error',
        message: 'Something went wrong',
        type: 'danger'
      });
      captcha.reset();
    }
  };

  const reCaptchaChange = (value) => {
    setWaiterData({ ...waiterData, reCaptchaKey: value });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWaiterData({ ...waiterData, [event.target.name]: event.target.value });
  };

  const handleDatePickerChange = (name: string, date: any) => {
    setWaiterData({ ...waiterData, [name]: date.format('YYYY-MM-DD') });
  };

  const handleCertFileChange = async (event: any) => {
    setWaiterData({ ...waiterData, certification: '' });
    const formData = new FormData();
    const fileInfo = event.target.files[0];
    if (fileInfo) {
      formData.append('file', event.target.files[0]);
      const _certUrl = await generalUploadResourceService(formData);
      if (_certUrl) {
        setWaiterData({ ...waiterData, certification: _certUrl });
      }
    }
  };

  const handleFileChange = async (event: any) => {
    const formData = new FormData();
    const fileInfo = event.target.files[0];
    if (fileInfo) {
      const extension = fileInfo.name.split('.')[1];

      if (profileFileType.indexOf(extension.toLowerCase()) === -1) {
        setNotifyOption({
          title: 'Error',
          message: 'Please upload jpg, jpeg and png file',
          type: 'danger'
        });
        return;
      }

      const fileSize = fileInfo.size;
      if (fileSize > 2 * 1000000) {
        setNotifyOption({
          title: 'Error',
          message: 'Please upload file less than 2MB.',
          type: 'danger'
        });
        return;
      }
      formData.append('file', event.target.files[0]);
      const _avatarUrl = await uploadResourceService(formData);
      if (_avatarUrl) {
        setWaiterData({ ...waiterData, avatarUrl: _avatarUrl });
      }
    }
  };

  const handleTextAreaChange = (name: string, text: string) => {
    setWaiterData({ ...waiterData, [name]: text });
  };

  return (
    <>
      <PageHeader title={APPLICATION_SUBMIT} />
      <Box padding={3}>
        <Box
          padding={3}
          sx={{
            background: 'white',
            boxShadow: '0px 2px 5px 5px lightgray',
            borderRadius: '5px'
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container direction="row">
              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Nombre:</FormHelperText>
                <OutlinedInput
                  name="firstName"
                  value={waiterData.firstName}
                  onChange={handleChange}
                  fullWidth
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight'
                  }}
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Apellidos:</FormHelperText>
                <OutlinedInput
                  name="lastName"
                  value={waiterData.lastName}
                  onChange={handleChange}
                  fullWidth
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight'
                  }}
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>F. Nacimiento</FormHelperText>
                <DatePicker
                  value={dayjs(
                    moment
                      .utc(waiterData.birthday, 'DD/MM/YYYY')
                      .format('YYYY-MM-DD')
                  )}
                  onChange={(date) => handleDatePickerChange('birthday', date)}
                  sx={{
                    width: '100%'
                  }}
                  format="DD/MM/YYYY"
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>DNI/NIE</FormHelperText>
                <OutlinedInput
                  name="dni"
                  value={waiterData.dni}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Nacionalidad</FormHelperText>
                <OutlinedInput
                  name="nationalID"
                  value={waiterData.nationalID}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Dirección</FormHelperText>
                <OutlinedInput
                  name="street"
                  value={waiterData.street}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Población</FormHelperText>
                <OutlinedInput
                  name="city"
                  value={waiterData.city}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Provincia</FormHelperText>
                <FormControl fullWidth>
                  <WAISelect
                    name="province"
                    handleChange={handleChange}
                    value={waiterData.province}
                    label="Selecciona la provincia"
                    options={provinceList.map((province) => ({
                      value: province,
                      label: province
                    }))}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>C.P.</FormHelperText>
                <InputMask
                  mask="99999"
                  value={waiterData.postalCode}
                  onChange={handleChange}
                >
                  <FormControl fullWidth>
                    <OutlinedInput name="postalCode" fullWidth required />
                  </FormControl>
                </InputMask>
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Email</FormHelperText>
                <OutlinedInput
                  type="email"
                  name="email"
                  value={waiterData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Teléfono</FormHelperText>
                <InputMask
                  mask="999-99-99-99"
                  value={waiterData.phoneNumber}
                  onChange={handleChange}
                >
                  <OutlinedInput name="phoneNumber" fullWidth required />
                </InputMask>
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Fotografía</FormHelperText>
                <OutlinedInput
                  name="avatarUrl"
                  onChange={handleFileChange}
                  fullWidth
                  type="file"
                  inputProps={{
                    id: 'file-input',
                    accept: '.jpg,.jpeg,.png'
                  }}
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>
                  Certificado de manipulación de alimentos
                </FormHelperText>
                <OutlinedInput
                  name="certification"
                  onChange={handleCertFileChange}
                  fullWidth
                  type="file"
                  inputProps={{
                    id: 'file-input'
                  }}
                />
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>
                  IBAN ( Introduce el IBAN de 24 caracteres )
                </FormHelperText>
                <InputMask
                  mask="**99-9999-9999-9999-9999-9999"
                  value={waiterData.iban}
                  onChange={(event) => {
                    handleTextAreaChange('iban', event.target.value);
                  }}
                >
                  <FormControl fullWidth>
                    <OutlinedInput fullWidth required />
                  </FormControl>
                </InputMask>
              </Grid>

              <Grid md={4} xs={12} item alignSelf="center" p={1}>
                <FormHelperText>Núm. S.S.</FormHelperText>
                <OutlinedInput
                  name="ssn"
                  value={waiterData.ssn}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} p={1}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={reCaptchaChange}
                  ref={(r) => setCaptchaRef(r)}
                />
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Button variant="contained" type="submit">
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
}

export default ApplicationCreate;
